<template>
    <RecentlyViewedProductsSimpleSliderWrapper
        :heading="$t('Recently viewed')"
        :threshold="RECENTLY_VIEWED_PRODUCTS_SLIDER_THRESHOLD"
        :sku="sku"
        :class="{ 'is-inside-container': isInsideContainer }"
        class="recently-viewed-products-slider"
    />
</template>

<script>
import { RECENTLY_VIEWED_PRODUCTS_SLIDER_THRESHOLD } from '@configs/recently-viewed-products';

import RecentlyViewedProductsSimpleSliderWrapper from '@organisms/RecentlyViewedProductsSimpleSliderWrapper/RecentlyViewedProductsSimpleSliderWrapper';

export default {
    name: 'RecentlyViewedProductsSlider',

    components: {
        RecentlyViewedProductsSimpleSliderWrapper,
    },

    props: {
        isInsideContainer: {
            type: Boolean,
            default: true,
        },

        sku: {
            type: String,
            default: '',
        },
    },

    beforeCreate() {
        this.RECENTLY_VIEWED_PRODUCTS_SLIDER_THRESHOLD = RECENTLY_VIEWED_PRODUCTS_SLIDER_THRESHOLD;
    },
};
</script>

<style lang="scss" scoped>
.recently-viewed-products-slider {
    &.is-inside-container {
        @apply -mx-3;
        width: calc(100% + (2 * #{theme('spacing.3')}));
    }
}
</style>
