import {
    CMS_COMPONENTS_PREFIX,
    COMPONENT_MULTISELECT_PROPS,
    COMPONENT_TEXTAREA_PROPS,
    COMPONENT_NUMBER_PROPS,
} from '@modules/page-builder/page-builder.config';

import { INPUT_FIELD_TYPES } from '@modivo-ui/components/InputField/v1';

export const parseArrayString = arrayString => {
    return arrayString
        .split(',')
        .map(item => item.trim())
        .filter(item => item);
};

export const getBaseComponentName = name => {
    return name === 'CmsText' ? name : name.replace(CMS_COMPONENTS_PREFIX, '');
};

export const getData = component => {
    if (!component) {
        return {};
    }

    let { data = {} } = component;

    if (typeof data === 'function') {
        data = data();
    }

    return data;
};

export const getPropsOptions = component => {
    const { options = {} } = getData(component);
    let baseOptions = {};

    if (component.extends) {
        const baseData = getData(component.extends);

        baseOptions = baseData.options || {};
    }

    return {
        ...baseOptions,
        ...options,
    };
};

export const getDefaultValues = component => {
    const { defaultConfig = {} } = getData(component);

    return defaultConfig;
};

export const getProps = (component, overridePropsConfig = {}) => {
    if (!component) {
        return {};
    }

    const { props = {}, mixins = [], extends: baseComponent = {} } = component;
    const baseComponentProps = baseComponent.props || {};

    const allProps = {
        ...baseComponentProps,
        ...mixins.reduce((acc, mixin) => ({ ...acc, ...mixin.props }), {}),
        ...props,
    };

    Object.entries(overridePropsConfig).forEach(([propName, propConfig]) => {
        const prop = allProps[propName];

        if (prop) {
            allProps[propName] = {
                ...prop,
                ...propConfig,
            };
        }
    });

    return allProps;
};

export const getPropsCustomFormConfig = component => {
    const { propsCustomFormConfig = {} } = getData(component);

    return propsCustomFormConfig;
};

export const getPropsCustomData = component => {
    const { propsCustomData = {} } = getData(component);

    return propsCustomData;
};

export const getSlotsDefaultValues = component => {
    const { slots = {} } = getDefaultValues(component);

    return slots;
};

export const getPropsDefaultValues = component => {
    const { props = {} } = getDefaultValues(component);

    return props;
};

export const getInputType = (component, propName) => {
    const isMultiselect = Object.entries(COMPONENT_MULTISELECT_PROPS).some(
        ([componentName, props]) => {
            return componentName === component && props.includes(propName);
        }
    );

    if (isMultiselect) {
        return 'multiselect';
    }

    const isNumber = Object.entries(COMPONENT_NUMBER_PROPS).some(
        ([componentName, props]) => componentName === component && props.includes(propName)
    );

    if (isNumber) {
        return INPUT_FIELD_TYPES.NUMBER;
    }

    return INPUT_FIELD_TYPES.TEXT;
};

export const isTextArea = (component, propName) =>
    Object.entries(COMPONENT_TEXTAREA_PROPS).some(
        ([componentName, props]) => componentName === component && props.includes(propName)
    );

export const load = async componentName => {
    return import(
        /* webpackChunkName: "cms-components", webpackMode: "lazy-once" */
        // eslint-disable-next-line prefer-template
        '@modules/page-builder/components/cms/' + componentName + '/' + componentName
    );
};

export const isPropSlotName = propName => /^slot/.test(propName);
